import React from "react";
import "./Skills.css";

const Skills = () => {
  return (
    <div className="skills container my-5">
      <div className="row">
        <div className="col-xl-6 col-lg-6 col-md-8 col-sm-12 order-1 order-xl-0 order-lg-0 order-md-0 skills__description">
          {/* <p>
            I have a Computer Science degree at Instituto Politecnico Nacional, my working experience is in the education and pharmacist sector. 
            <br/>
            <br/>
            I have worked in the development of web applications. I have experience with different technologies such as ReactJS, JavaScript, HTML, CSS, SQL, Strapi, among others.
          </p> */}
          <p>
            I have a Computer Science Degree at Instituto Politecnico Nacional, my working experience has been focused in the education and pharmacist sector.
            <br/>
            <br/>
            I'm experienced in the development of web applications. My valuable job skills are ReactJS, JavaScript, NodeJS, PHP, HTML, CSS, SQL, NoSQL, Strapi, among others.
          </p>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-4 col-sm-12 order-0 order-xl-1 order-lg-1 order-md-1 d-flex align-items-center justify-content-center" data-aos="fade-up" data-aos-duration="1000">
          <h2 className="blue-color fs-1 mb-3">About me</h2>
          <span className="skills__subtitle-background">ABOUT ME</span>
        </div>
      </div>
    </div>
  );
};

export default Skills;
