import React from 'react';
import './Header.css';
import { SiGmail, SiGithub } from 'react-icons/si';
import { FaLinkedinIn } from 'react-icons/fa';
import { HiPhone } from 'react-icons/hi';

const Header = () => {
  return (
    <div className='container-fluid header'>
        <div className='row'>
            <div className='col-12 header__background py-5'>
                <div className='glass__card'>
                  <div className='header__background-title'>
                    <h1 className='fw-bolder white-color text-center'>{'<OMAR CAMACHO />'}</h1>
                    <h1 className='fw-bolder white-color text-center'>WEB DEVELOPER</h1>
                  </div>
                  <hr className='header__hr blue-color' />
                  <h2 className='fw-light white-color mt-4'>CONTACT ME</h2>
                  <div className='col-12 header__contact'>
                    <div className='header__contact-icon' data-aos="fade-up" data-aos-duration="1200">
                      <a href='mailto:juanomarcamacho@gmail.com' target='_blank' rel='noreferrer' className='white-color'>
                        <SiGmail className='fs-3 mt-3 white-color' />
                      </a>
                    </div>
                    {/* <div className='header__contact-icon' data-aos="fade-up" data-aos-duration="1000">
                      <a href='https://github.com/RichOmaar' target='_blank' rel='noreferrer' className='white-color'>
                        <SiGithub className='fs-3 mt-3 white-color' />
                      </a>
                    </div> */}
                    <div className='header__contact-icon' data-aos="fade-up" data-aos-duration="800">
                      <a href='https://www.linkedin.com/in/juan-omar-camacho-gil/' target='_blank' rel='noreferrer' className='white-color'>
                        <FaLinkedinIn className='fs-3 mt-3 white-color' />
                      </a>
                    </div>
                    <div className='header__contact-icon' data-aos="fade-up" data-aos-duration="800">
                      <a href='tel:+525534080960' target='_blank' rel='noreferrer' className='white-color'>
                        <HiPhone className='fs-3 mt-3 white-color' />
                      </a>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Header;