import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <div className='footer py-4'>
        <p className='fw-light fs-6 text-center m-0'>Designed & Developed with love by Omar Camacho. 2023</p>
    </div>
  )
}

export default Footer;